export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    show: true,
    type: 'checkbox',
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'contains_media_plans',
    label: 'Created Media Plans',
    sortable: true,
    show: false,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'sales_rep',
    label: 'Sales Rep',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'account',
    label: 'Account',
    sortable: true,
    show: false,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'advertiser_id',
    label: 'Advertiser *',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width',
    //
  },
  {
    key: 'station_id',
    label: 'Station',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width',
  },
  {
    key: 'account_manager_id',
    label: 'Account Manager',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width',
  },
  {
    key: 'network',
    label: 'Network',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'category',
    label: 'Category',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize ',
  },
  {
    key: 'location',
    label: 'Location',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'placement_name',
    label: 'Placement Name',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'assets_description',
    label: 'Assets Description',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'demo',
    label: 'Demo',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize ',
  },
  {
    key: 'generate_trade_items',
    label: 'Generate Trade Items',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'flight_start',
    label: 'Flight Start',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'flight_end',
    label: 'Flight End',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'impressions',
    label: 'Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'cpm',
    label: 'CPM',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
  {
    key: 'total_gross',
    label: 'Total Gross',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize fixed-width-md',
  },
]
