
import ViewModel from '@/models/ViewModel'
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import Widget from '@/components/Widget/Widget.vue'
import PrePlan from '@/models/PrePlan'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import moment from 'moment'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { currencyMask } from '@/models/interface/Masks'
import PrePlanConversionItem from '@/models/PrePlanConversionItem'
import IconAction from '@/components/IconAction/IconAction.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import { groupBy } from 'lodash'
import WebMessage from '@/models/WebMessage'
import MediaPlan from '@/models/MediaPlan'
import SelectOption from '@/models/interface/SelectOption'
import { FormWizard, TabContent } from 'vue-form-wizard'
import pre_plan_convert_fields from './pre-plan-convert-fields'

@Component({
  components: {
    PageHeader,
    Widget,
    SearchInput,
    DataTable,
    SelectPicker,
    FooterNav,
    FormInput,
    DatePicker,
    IconAction,
    CompanyPicker,
    UserPicker,
    FormWizard,
    TabContent,
  },
})
export default class PrePlanConvert extends ViewModel {
  @Ref() public validator!: any

  @Ref() public selectAdvValidator!: any

  @Ref() public formWizard!: any

  public loading: boolean = true

  public items: PrePlanConversionItem[] = []

  public selected: any = []

  public query: string[] = []

  public step = 0

  public fields: any = []

  public preplan: PrePlan = new PrePlan()

  public display_selected_rows: boolean = false

  public created_media_plans: any = {}

  public searching_for_media_plans: boolean = false

  public loaded_media_plans: any = []

  public modals: any = {
    media_plan_view: false,
    select_advertisers: false,
  }

  public unique_accounts: any = []

  public unique_sales_reps: any = []

  public selected_accounts: any = []

  public select_advertiser_table_fields: any = [
    {
      key: 'account',
      label: 'Account',
      sortable: true,
      show: true,
      class: 'text-center align-middle text-capitalize fixed-width-md',
    },
    {
      key: 'advertiser_id',
      label: 'Advertiser *',
      sortable: true,
      show: true,
      class: 'text-center align-middle text-capitalize fixed-width',
      //
    },
  ]

  public select_station_table_fields: any = [
    {
      key: 'name',
      label: 'Sales Rep',
      sortable: true,
      show: true,
      class: 'text-center align-middle text-capitalize fixed-width-md',
    },
    {
      key: 'station_id',
      label: 'Station *',
      sortable: true,
      show: true,
      class: 'text-center align-middle text-capitalize fixed-width',
      //
    },
  ]

  public get allAccountsAssigned() {
    return this.unique_accounts.every((account: any) => !account.advertiser_id)
  }

  public get allStationsAssigned() {
    return this.unique_sales_reps.every((account: any) => !account.station_id)
  }

  public get selected_row_ids() {
    return this.selected
  }

  public filterSelected() {
    this.display_selected_rows = !this.display_selected_rows
  }

  public mounted() {
    this.getPrePlanItemsToConvert()
  }

  public get indeterminate(): boolean {
    return (
      this.selected_accounts.length > 0
      && this.selected_accounts.length < this.unique_accounts.length
    )
  }

  public get masks() {
    return {
      currency: currencyMask,
    }
  }

  public get preloadUserOption() {
    return [new SelectOption(this.user.name, this.user.id)]
  }

  public get allSelected() {
    return this.selected.length === this.items.length
  }

  public get filter_items(): PrePlanConversionItem[] {
    let items = this.items
    if (this.query.length > 0) {
      items = this.items.filter((item: any) => {
        for (const q of this.query) {
          const [key, value] = q.split(':')
          if (item[key] !== value) {
            return false // Skip this item if any query condition doesn't match
          }
        }
        return true // Include the item if all query conditions match
      })
    }

    if (this.display_selected_rows) {
      items = items.filter((item: any) => this.selected.includes(item.id))
    }

    return items || []
  }

  public get total_gross() {
    let res = this.filter_items.reduce((acc: any, item: any) => acc + item.total_gross, 0)
    if (!isNaN(res)) return res
    return 0
  }

  public get impressions() {
    let res = this.filter_items.reduce((acc: any, item: any) => acc + item.impressions, 0)
    if (!isNaN(res)) return res
    return 0
  }

  public get total_net_cpm() {
    let res = this.total_gross / (this.impressions / 1000)
    if (!isNaN(res)) return res
    return 0
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get filter_options() {
    return this.generateFilterOptions()
  }

  public getPrePlanItemsToConvert() {
    this.loading = true
    PrePlan.getExcelData(this.$route.params.id).then((res: any) => {
      this.fields = pre_plan_convert_fields.filter(f => f.show)

      this.preplan = res.data.result.preplan

      let acc: any = []

      let contains_created_media_plan: any = {}

      res.data.result.items.forEach(group => {
        group.forEach(item => {
          let i = PrePlanConversionItem.toObject(item)
          this.preplan.items.forEach((itm: any) => {
            if (itm.media_plan_ids && itm.media_plan_ids.length) {
              itm.media_plan_ids.forEach(element => {
                if (element[i.id]) {
                  if (!contains_created_media_plan[i.id]) {
                    contains_created_media_plan[i.id] = []
                  }
                  if (element[i.id]) contains_created_media_plan[i.id].push(element[i.id])

                  contains_created_media_plan[i.id] = [
                    ...new Set(contains_created_media_plan[i.id]),
                  ]
                }
              })
            }
          })
          acc.push(i)
        })
      })

      if (Object.keys(contains_created_media_plan).length) {
        // add extra column to table
        this.fields = pre_plan_convert_fields
          .map((field: any) => {
            if (field.key === 'contains_media_plans') {
              field.show = true
            }
            return field
          })
          .filter(f => f.show)

        this.created_media_plans = contains_created_media_plan
      }

      this.items = acc
      this.groupUniqueAccounts()
      setTimeout(() => {
        this.loading = false
      }, 500)
    })
  }

  public generateFilterOptions() {
    if (!this.items) return []
    let options: any = []
    let find = ['account', 'category', 'location', 'assets_description', 'demo']

    find.forEach((f: any) => {
      this.items.forEach((item: any) => {
        options.push({
          name: `${f}:${item[f]}`,
          value: `${f}:${item[f]}`,
        })
      })
    })

    options = options.filter(
      (v: any, i: any, a: any) => a.findIndex((t: any) => t.value === v.value) === i,
    )
    // sort by name
    options.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    return options
  }

  public convertToMediaPlans() {
    const start_conversion = () => {
      this.loading = true

      let updated_rows: any = this.filter_items.filter((item: any) => {
        // "item.is_dirty && "" removed from if statement, we need only the selected
        if (this.selected.includes(item.id)) {
          return item
        }
        return false
      })

      let obj = {}

      updated_rows.forEach((item: any) => {
        obj = Object.assign(obj, item.apiData)
      })

      PrePlan.createMediaPlans(this.preplan.id, {
        group_id: this.selected,
        updated_rows: obj,
      })
        .then((res: any) => {
          this.loading = false
          if (res.status === 200) {
            this.$router.push({ name: 'MediaPlans' })
          }
        })
        .catch(error => {
          this.loading = false
        })
    }

    this.validator.validateWithInfo().then((validate: any) => {
      if (validate.isValid) {
        start_conversion()
      } else {
        WebMessage.warning('Some Advertisers are empty, please select an advertiser for each row.')
      }
    })
  }

  public viewCreatedMediaPlans(item_id: any) {
    this.modals.media_plan_view = true
    this.searching_for_media_plans = true
    MediaPlan.bulkFind(this.created_media_plans[item_id])
      .then(response => {
        this.loaded_media_plans = response
        this.searching_for_media_plans = false
      })
      .catch(error => {
        this.searching_for_media_plans = false
        this.modals.media_plan_view = false
      })
  }

  public groupUniqueAccounts() {
    // Get unique accounts & sales reps
    let grouped: any = groupBy(this.filter_items, 'account')

    let keys = Object.keys(grouped)

    this.unique_accounts = keys.map(key => ({
      account: key,
      advertiser_id: null,
      slug: key.toLowerCase().replaceAll(/ /g, '_'),
    }))

    grouped = groupBy(this.filter_items, 'sales_rep.name')

    keys = Object.keys(grouped)

    this.unique_sales_reps = keys.map(key => ({
      name: key,
      station_id: null,
      slug: key.toLowerCase().replaceAll(/ /g, '_'),
    }))

    this.modals.select_advertisers = true
  }

  public applyUpdates() {
    this.selectAdvValidator.handleSubmit(() => {
      let account_map = groupBy(this.unique_accounts, 'account')
      let sales_rep_map = groupBy(this.unique_sales_reps, 'name')

      this.items.forEach((item: any) => {
        if (account_map[item.account]) {
          item.advertiser_id = account_map[item.account][0].advertiser_id
          item.advertiser = account_map[item.account][0].advertiser
        }

        if (sales_rep_map[item.sales_rep.name]) {
          item.station_id = sales_rep_map[item.sales_rep.name][0].station_id
          item.station = sales_rep_map[item.sales_rep.name][0].station
        }
      })

      this.modals.select_advertisers = false
    })

    this.loading = false
  }

  public cancelSelectingAdvertiser() {
    this.selected_accounts = []
    this.unique_accounts = []
    this.modals.select_advertisers = false
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex)
    }
  }

  public nextTab() {
    this.formWizard.nextTab()
  }

  public prevTab() {
    this.formWizard.prevTab()
  }
}
