// @ts-ignore
import { Md5 } from 'ts-md5/dist/md5'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'
import Model from './interface/Model'
import User from './User'

export default class PrePlanConversionItem extends Model {
  public id: any = null

  public sales_rep: any = ''

  public advertiser_id: any = null

  public station_id: any = null

  public account_manager_id: any = null

  public account: any = ''

  public network: any = ''

  public category: any = ''

  public location: any = ''

  public placement_name: any = ''

  public assets_description: any = ''

  public demo: any = ''

  public generate_trade_items: boolean = true

  public flight_start: any = ''

  public flight_end: any = ''

  public impressions: any = 0

  public total_gross: any = 0

  public _hash: any = null

  public system: SystemtModule | any = getModule(SystemtModule)

  public get cpm() {
    if (this.total_gross == 0 || this.impressions == 0) return 0
    return this.total_gross / (this.impressions / 1000)
  }

  public toObject(source: any) {
    // @ts-ignore
    let instance = this.clone()
    instance._hash = null
    delete source.cpm
    source.flight_start = source.flight_start.split('T')[0]
    source.flight_end = source.flight_end.split('T')[0]
    instance = Object.assign(instance, source)
    instance.updateChecksum()
    if (!source.account_manager_id) {
      instance.account_manager_id = this.system.user.id
    }
    return instance
  }

  public get is_dirty(): boolean {
    if (this._hash === null) return false
    return this._hash !== this.calculateChecksum()
  }

  public calculateChecksum(): string | Int32Array {
    return Md5.hashStr(JSON.stringify(this.apiData))
  }

  public updateChecksum() {
    this._hash = this.calculateChecksum()
  }

  public get apiData() {
    return {
      [this.id]: {
        flight_end: this.flight_end,
        flight_start: this.flight_start,
        impressions: this.impressions,
        generate_trade_items: this.generate_trade_items,
        total_gross: this.total_gross,
        account_manager_id: this.account_manager_id,
        advertiser_id: this.advertiser_id,
        station_id: this.station_id,
      },
    }
  }
}
